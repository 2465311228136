<template>
    <b-row>
      <b-col
        xl="6"
      >
        <b-form-group
          label="No Penerimaan"
          label-for="No Penerimaan"
        >
          <b-form-input
            id="no_penerimaan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_penerimaan"
          />
        </b-form-group>

        <b-form-group
          label="Tanggal Penerimaan"
          label-for="tgl_penerimaan"
        >
          <b-form-datepicker
            id="tgl_penerimaan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tgl_penerimaan"
            locale="id"
          />
        </b-form-group>

        <b-form-group
          label="Nomor Register"
          label-for="no_register"
        >
          <b-form-input
            id="no_register"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_register"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Register"
          label-for="tgl_register"
        >
          <b-form-datepicker
            id="tgl_register"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tgl_register"
            locale="id"
          />
        </b-form-group>
        <b-form-group
          label="No Surat"
          label-for="no_surat"
        >
          <b-form-textarea
            id="no_surat"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_surat"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Surat"
          label-for="Tanggal Surat"
        >
          <b-form-datepicker
            id="Tanggal Surat"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tgl_surat"
            locale="id"
          />
        </b-form-group>
        <b-form-group
          label="Jenis Penyampaian"
          label-for="Jenis Penyampaian"
        >
          <b-form-input
            id="Jenis Penyampaian"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.jenis_penyampaian"
          />
        </b-form-group>
      </b-col>
      <b-col
        xl="6"
      >
        <b-form-group
          label="Diterima Melalui"
          label-for="Diterima Melalui"
        >
          <b-form-input
            id="Diterima Melalui"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.diterima_melalui"
          />
        </b-form-group>
        <b-form-group
          label="Perihal"
          label-for="Perihal"
        >
          <b-form-textarea
            id="Perihal"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.perihal"
          />
        </b-form-group>
        <b-form-group
          label="Jenis Perkara"
          label-for="Jenis Perkara"
        >
          <b-form-input
            id="Jenis Perkara"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.jenis_perkara"
          />
        </b-form-group>
        <b-form-group
          label="Lingkungan Peradilan"
          label-for="Lingkungan Peradilan"
        >
          <b-form-input
            id="Lingkungan Peradilan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.lingkungan_peradilan"
          />
        </b-form-group>
        <b-form-group
          label="Lokasi Laporan"
          label-for="Lokasi Laporan"
        >
          <b-form-input
            id="Lokasi Laporan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.kota"
          />
        </b-form-group>
        <b-form-group
          label="No Putusan"
          label-for="No Putusan"
        >
          <b-form-input
            id="No Putusan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_putusan"
          />
        </b-form-group>
        <b-form-group
          label="Keterangan"
          label-for="Keterangan"
        >
          <b-form-textarea
            id="Keterangan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.keterangan"
          />
        </b-form-group>
     
      </b-col>
      <!-- <b-col xl="12" class="mt-5 mb-5">
        <div class="d-flex justify-content-end">
          <b-button class="btn mr-1" v-b-modal.modal_confirm_investigasi variant="primary">Teruskan ke Investigasi dan Advokasi</b-button>
          <div>
            <b-modal id="modal_confirm_investigasi" title="Konfirmasi" centered hide-footer>
              <div class="d-flex justify-content-center" >
                <h4 class="my-4" style="text-align: center;">Apakah anda yakin teruskan ke Investigasi dan Advokasi?</h4>
              </div>
              <div class="d-flex justify-content-end">
                <b-form-group>
                  <b-button class="btn mr-1" variant="primary" @click="update('investigasi-advokasi')">Ya</b-button>
                </b-form-group>
                <b-form-group>
                  <b-button class="btn" variant="outline-primary" ref="btnClose">Tidak</b-button>
                </b-form-group>
              </div>
            </b-modal>
          </div>


          <b-button class="btn" v-b-modal.modal_confirm_pendahuluan variant="primary">Teruskan ke Pemeriksaan Pendahuluan</b-button>
          <div>
            <b-modal id="modal_confirm_pendahuluan" title="Konfirmasi" centered hide-footer>
              <div class="d-flex justify-content-center" >
                <h4 class="my-4" style="text-align: center;">Apakah anda yakin teruskan ke Pemeriksaan Pendahuluan?</h4>
              </div>
              <div class="d-flex justify-content-end">
                <b-form-group>
                  <b-button class="btn mr-1" variant="primary" @click="update('pemeriksaan-pendahuluan')">Ya</b-button>
                </b-form-group>
                <b-form-group>
                  <b-button class="btn" variant="outline-primary" ref="btnClose">Tidak</b-button>
                </b-form-group>
              </div>
            </b-modal>
          </div>
        </div>
      </b-col> -->
    </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import {
  updateVerifikasi,
} from '@/connection-api/master'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import KasusPosisi from '@/views/kasus-posisi/list/List.vue'
import PokokLaporan from '@/views/pokok-laporan/list/List.vue'
import DataPendukung from '@/views/data-pendukung/list/List.vue'
import Analisis from '@/views/analisis/list/List.vue'
import Kesimpulan from '@/views/kesimpulan/list/List.vue'
import SaranAnalisis from '@/views/saran-analisis/list/List.vue'
import TandaTangan from '@/views/tanda-tangan/list/List.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormDatepicker,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    KasusPosisi,
     BFormCheckbox,
    BTab,
    BTabs,
    Pelapor,
    Terlapor,
    KuasaPelapor,
    PokokLaporan,
    DataPendukung,
    Analisis,
    Kesimpulan,
    SaranAnalisis,
    TandaTangan,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
  },
  methods: {
   
    async update(params) {
      switch (params) {
        case 'investigasi-advokasi':
          this.localData.jenis_cta = "investigasi-advokasi";
          break;
        case 'pemeriksaan-pendahuluan':
          this.localData.jenis_cta = 'investigasi-advokasi';
          break;
      }
      
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await updateVerifikasi(this.localData)
      if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$emit('refetchData')
              this.$router.push({name: 'tpl-analisis-laporan'})
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
    },
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
