<template>
  <div>
    <!-- <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$route.params.mode ? 'Ke Halaman Utama' : ($route.params.redirect == 'panel-daftar-tunggu' ? 'Buat Jadwal Sidang Panel' : 'Buat Jadwal Sidang Pleno')"
      back-button-text="Kembali"
      next-button-text="Lanjut"
      class="mb-3"
      @on-complete="$route.params.mode ? keHalamanUtama() : ($route.params.redirect == 'panel-daftar-tunggu' ? buatSidangPanel() : buatSidangPleno())"
    > -->
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="'Ke Halaman Utama'"
      back-button-text="Kembali"
      next-button-text="Lanjut"
      class="mb-3"
      @on-complete="keHalamanUtama()"
    >
      <tab-content
        title="Detail Laporan"
   
      >
        <!-- <validation-observer
          ref="penerimaanRules"
          tag="form"
        > -->
          <penerimaan :localData="localData" />
          <b-modal id="buat_panel_jadwal" size="lg" title="Jadwal Panel" ref="btnClose" hide-footer>
          <tambah-jadwal-panel
            :timPanelOptions="timPanelOptions.options"
            :timAhliOptions="timAhliOptions.options"
          />
          </b-modal>
          <b-modal id="buat_pleno_jadwal" size="lg" title="Jadwal Pleno" ref="btnClose" hide-footer>
          <tambah-jadwal-pleno
            :timAhliOptions="timAhliOptions.options" :localDatas="localData"
          />
          </b-modal>
        <!-- </validation-observer> -->
      </tab-content>
      <!-- <tab-content
        title="Persidangan"

      >
          <monitoring-persidangan :localData="localData" />
      </tab-content> -->
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  getTimPanel,
  getTimAhli,
} from '@/connection-api/master'
import {
  uploadDokumen,
  downloadDokumen,
  tambahPihak,
  tambahPutusan,
  generateNomorPenerimaan,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import { codeIcon } from './code'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import Korespondensi from "./component/Korespondensi.vue";
import ModalDokumen from './component/ModalDokumen.vue'
import Verifikasi from './component/Verifikasi.vue'
import Penerimaan from './component/Penerimaan.vue'
import MonitoringPersidangan from './component/MonitoringPersidangan.vue'
import TambahJadwalPanel from './component/TambahJadwalPanel.vue'
import TambahJadwalPleno from './component/TambahJadwalPleno.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    Korespondensi,
    ModalDokumen,
    BFormDatepicker,
    Verifikasi,
    // AnalisisLaporan,
    Penerimaan,
    MonitoringPersidangan,
    TambahJadwalPanel,
    TambahJadwalPleno,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      centeredModalVisible: false,
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      timPanelOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tim Panel --' },
        ],
      },
      timAhliOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tim Ahli --' },
        ],
      },
    }
  },
  props: {
    jenisPenyampaianOptions: {},
    kantorPenghubungOptions: {},
    jenisPerkaraOptions: {},
    lingkunganPeradilanOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    jenisDokumenOptions: {},
    jenisPihakTerlibatOptions: {},
    localData: {},
  },
  async mounted() {
     const respTimPanel = await getTimPanel()
    this.mappingSelectOption(respTimPanel.data, this.timPanelOptions.options)

    const respTimAhli = await getTimAhli()
    this.mappingSelectOption(respTimAhli.data, this.timAhliOptions.options)
  },
  methods: {
    async keHalamanUtama(){
      this.$router.replace(`/persidangan/monitoring/`)
    },
    async buatSidangPanel(){
      this.$root.$emit('bv::show::modal', 'buat_panel_jadwal')
    },
    async buatSidangPleno(){
      this.$root.$emit('bv::show::modal', 'buat_pleno_jadwal')
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
             if (success) {
                resolve(true)
              } else {
                reject()
              }
          }
        })
      })
    },
     makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
     mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
}
</script>
