<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >
          <label>No Register</label>
          <div class="d-flex">
            <b-form-input
              id="no_register"
              autofocus
              trim
              placeholder="Pilih no register"
              :disabled="true"
              class="mr-2"
              v-model="localDatas.no_register"
              :key="refresh"
            />
            <!-- <b-button class="btn" variant="primary" v-b-modal.pilih_laporan_tambah_jadwal_pleno>Cari</b-button> -->
          </div>
            <small class="mt-1" style="color: red;" v-if="error.no_register">{{error.message}}</small>
        </b-col>
        <b-modal :id="`pilih_laporan_tambah_jadwal_pleno`" size="xl" title="Laporan" ref="btnClose" hide-footer>
          <persidangan-monitoring :is_pilihan="true" @getDataLaporan="getDataLaporan" />
        </b-modal>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Tim Majelis</label>
          <div class="d-flex">
            <b-form-input
              id="tim_majelis"
              autofocus
              trim
              placeholder="Pilih Tim Majelis"
              :disabled="true"
              class="mr-2"
              v-model="localData.tim_majelis"
              :key="refresh"
            />
            <b-button class="btn" variant="primary" v-b-modal.pilih_tambah_tim_majelis>Cari</b-button>
          </div>
            <small class="mt-1" style="color: red;" v-if="error.no_register">{{error.message}}</small>
        </b-col>
        <b-modal :id="`pilih_tambah_tim_majelis`" size="xl" title="Tim Majelis" ref="btnClose" hide-footer>
          <tim-majelis :is_pilihan="true" @getDataTimMajelis="getDataTimMajelis" />
        </b-modal>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Tenaga Ahli</label>
          <div class="d-flex">
            <b-form-input
              id="tenaga_ahli"
              autofocus
              trim
              placeholder="Pilih Tenaga Ahli"
              :disabled="true"
              class="mr-2"
              v-model="localData.tenaga_ahli"
              :key="refresh"
            />
            <b-button class="btn" variant="primary" v-b-modal.pilih_tambah_tenaga_ahli>Cari</b-button>
          </div>
            <small class="mt-1" style="color: red;" v-if="error.tim_ahli">{{error.message}}</small>
        </b-col>
        <b-modal :id="`pilih_tambah_tenaga_ahli`" size="xl" title="Tenaga Ahli" ref="btnClose" hide-footer>
          <tenaga-ahli :is_pilihan="true" @getDataTenagaAhli="getDataTenagaAhli" />
        </b-modal>
        <!-- <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Tim Panel</label>
          <div class="d-flex">
            <b-form-input
              id="tim_panel"
              autofocus
              trim
              placeholder="Pilih Tim Panel"
              :disabled="true"
              class="mr-2"
              v-model="localData.tim_panel"
              :key="refresh"
            />
            <b-button class="btn" variant="primary" v-b-modal.pilih_tambah_tim_panel>Cari</b-button>
          </div>
            <small class="mt-1" style="color: red;" v-if="error.tim_ahli">{{error.message}}</small>
        </b-col> -->
        <!-- <b-modal :id="`pilih_tambah_tim_panel`" size="xl" title="Tim Panel" ref="btnClose" hide-footer>
          <tim-panel :is_pilihan="true" @getDataTimPanel="getDataTimPanel" />
        </b-modal> -->
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2 mt-2"
        >
          <label>Sekertaris Pengganti</label>
          <div class="d-flex">
            <b-form-input
              id="sekertaris_pengganti"
              autofocus
              trim
              placeholder="Pilih Sekertaris"
              :disabled="true"
              class="mr-2"
              v-model="localData.sekertaris_pengganti"
              :key="refresh"
            />
            <b-button class="btn" variant="primary" v-b-modal.pilih_tambah_sekertaris_pengganti>Cari</b-button>
          </div>
            <small class="mt-1" style="color: red;" v-if="error.no_register">{{error.message}}</small>
        </b-col>
        <b-modal :id="`pilih_tambah_sekertaris_pengganti`" size="xl" title="Sekertaris Pengganti" ref="btnClose" hide-footer>
          <sekertaris-pengganti :is_pilihan="true" @getDataSekertarisPengganti="getDataSekertarisPengganti" />
        </b-modal>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Tanggal</label>
            <b-form-datepicker id="tanggal" locale="id" v-model="localData.tanggal"></b-form-datepicker>
            <small class="mt-1" style="color: red;" v-if="error.tanggal">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Tempat</label>
           <b-form-input
              id="hari"
              autofocus
              trim
              placeholder="Tempat"
              v-model="localData.tempat"
            />
            <small class="mt-1" style="color: red;" v-if="error.kota_id">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Waktu</label>
             <b-form-timepicker locale="id" v-model="localData.waktu"></b-form-timepicker>
            <small class="mt-1" style="color: red;" v-if="error.kota_id">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Keterangan Tambahan Wakil Ketua KY</label>
             <b-form-textarea rows="3" v-model="localData.keterangan"></b-form-textarea>
            <small class="mt-1" style="color: red;" v-if="error.Keterangan">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <b-button class="btn" variant="primary" @click="tambahData">Simpan</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker, BFormTimepicker, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
    tambahDataPlenoJadwal,
} from '@/connection-api/master'
import PersidanganMonitoring from '@/views/persidangan/monitoring/list/List.vue'
import TimMajelis from '@/views/tim-majelis/list/List.vue'
// import TimPanel from '@/views/tim-panel/list/List.vue'
import TenagaAhli from '@/views/tenaga-ahli/list/List.vue'
import SekertarisPengganti from '@/views/user/list/List.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    BModal,
    PersidanganMonitoring,
    TimMajelis,
    // TimPanel,
    TenagaAhli,
    SekertarisPengganti,
  },
  data() {
    return {
      error: {},
      refresh: 0,
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
        tenaga_ahli_id: null,
        // tim_panel_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    timAhliOptions: {},
    localDatas: {},
    // timPanelOptions: {},
  },
  methods: {
   
    getDataLaporan(id,no_register)
    {
      this.localData.laporan_id = id
      this.localData.no_register = no_register
      this.refresh += 1
    },
     getDataTim(id,nama)
    {
      this.localData.tim_id = id
      this.localData.tim = nama
      this.refresh += 1
    },
    //  getDataTimPanel(id,nama)
    // {
    //   this.localData.tim_panel_id = id
    //   this.localData.tim_panel = nama
    //   this.refresh += 1
    // },
    getDataTimMajelis(id,nama)
    {
      this.localData.tim_majelis_id = id
      this.localData.tim_majelis = nama
      this.refresh += 1
    },
     getDataTenagaAhli(id,nama)
    {
      this.localData.tenaga_ahli_id = id
      this.localData.tenaga_ahli = nama
      this.refresh += 1
    },
    getDataSekertarisPengganti(id,nama)
    {
      this.localData.sekertaris_pengganti_id = id
      this.localData.sekertaris_pengganti = nama
      this.refresh += 1
    },
    async uploadLogo(id)
    {
      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async tambahData()
    {

      const data = {
        laporan_id: this.localDatas.id,
        tim_majelis_id: this.localData.tim_majelis_id, 
        tenaga_ahli_id: this.localData.tenaga_ahli_id, 
        sekertaris_pengganti_id: this.localData.sekertaris_pengganti_id, 
        tanggal: this.localData.tanggal, 
        tempat: this.localData.tempat, 
        waktu: this.localData.waktu, 
        keterangan: this.localData.keterangan, 
      }
      const response = await tambahDataPlenoJadwal(data)
      if(response.data.success === true)
      {
        this.uploadLogo(response.data.id)
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Jadwal Pleno',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.localData.nama_negara = null
          this.localData.kode_negara = null
          this.$root.$emit('bv::hide::modal', 'buat_pleno_jadwal')
          this.$router.push('/persidangan/pleno/jadwal')
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Jadwal Pleno',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
