<template>
    <b-row>
      <b-col
        xl="6"
      >
        <b-form-group
          label="Tanggal LPP"
          label-for="tgl_lpp"
        >
          <b-form-datepicker
            id="tgl_lpp"
            autofocus
            trim
            placeholder=""
            :disabled="true"

          />
        </b-form-group>

        <b-form-group
          label="Jadwal Sidang Panel"
          label-for="jadwal_sidang_panel"
        >
          <b-form-datepicker
            id="jadwal_sidang_panel"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tanggal_jadwal_panel"
            locale="id"
          />
        </b-form-group>

        <b-form-group
          label="Hasil Sidang Panel"
          label-for="hasil_sidang_panel"
        >
          <!-- <b-form-textarea
            id="hasil_sidang_panel"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.hasil_sidang_panel"
          /> -->
          <div class="form-control" style="height: auto; background-color: #efefef;">
                          <p v-html="localData.hasil_sidang_panel"></p>
          </div>
        </b-form-group>
        <b-form-group
          label="Tanggal LHP"
          label-for="tgl_lhp"
        >
          <b-form-datepicker
            id="tgl_lhp"
            autofocus
            trim
            placeholder=""
            :disabled="true"
           
          />
        </b-form-group>
        <b-form-group
          label="Jadwal Sidang Pleno"
          label-for="jadwal_sidang_pleno"
        >
          <b-form-datepicker
            id="jadwal_sidang_pleno"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tanggal_jadwal_pleno"
            locale="id"
          />
        </b-form-group>
        <b-form-group
          label="Hasil Sidang Pleno"
          label-for="hasil_sidang_pleno"
        >
          <!-- <b-form-textarea
            id="hasil_sidang_pleno"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.hasil_sidang_pleno"
          /> -->
          <div class="form-control" style="height: auto; background-color: #efefef;">
                          <p v-html="localData.hasil_sidang_pleno"></p>
          </div>
        </b-form-group>
        <b-form-group
          label="Tanggal Putusan Sidang Pleno"
          label-for="tgl_putusan_sidang_pleno"
        >
          <b-form-datepicker
            id="tgl_putusan_sidang_pleno"
            autofocus
            trim
            placeholder=""
            :disabled="true"
 
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Surat Pelapor"
          label-for="tgl_surat_pelapor"
        >
          <b-form-datepicker
            id="tgl_surat_pelapor"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tgl_surat"
            locale="id"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Surat Ke Mahkamah Agung"
          label-for="tgl_surat_ke_mahkamah_agung"
        >
          <b-form-datepicker
            id="tgl_surat_ke_mahkamah_agung"
            autofocus
            trim
            placeholder=""
            :disabled="true"

          />
        </b-form-group>
        <b-form-group
          label="Hasil MKH"
          label-for="hasil_mkh"
        >
          <b-form-textarea
            id="hasil_mkh"
            autofocus
            trim
            placeholder=""
            :disabled="true"
           
          />
        </b-form-group>
       
      </b-col>
    </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
// import ModalUnitUtama from "./ModalUnitUtama.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    // ModalUnitUtama,
     BFormCheckbox,
     BFormDatepicker,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
  },
  methods: {
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
